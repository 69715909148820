<template>
    <div>
    </div>
</template>

<script>
export default {
    name: "organPayCode",
    data() {
        return {
        }
    },

    computed:{
        user() {
            return this.$store.state.user;
        },
    },
    // 在 Vue 组件中使用 $watch 监听 Vuex 数据的变化
    watch: {
        user() {}
    },

    props: {
        payParams: {
            type: Object,
            default() {
                return {}
            }
        },
    },

    mounted() {
        console.log(this.payParams)
        this.checkPayParams();
    },

    methods: {
        //立即支付
        checkPayParams(){
            //支付
            if(parseInt(this.payParams.type) == 1){
                this.applyBankCardPay()
            }
            //实时转账
            if(parseInt(this.payParams.type) == 2){
                this.transferMoneyToPay()
            }
            //延时分账
            if(parseInt(this.payParams.type) == 3){
                this.delayedAccountToPay()
            }
        },
        //创建支付对象申请 返回订单
        applyBankCardPay() {
            var that = this;
            var params = {};
            params.payAmt = that.utils.keepTwoDecimalFull(that.payParams.payAmt);
            params.payType = that.payParams.orderType;
            that.newApi.corpPaymentCreatePay(params).then((res) => {
                if(res.isSuccess == 1){
                    const routeData = that.$router.resolve({path: '/toPay/' + res.data.order_no, query: {type: 1}});
                    //到newAdapay页面去跳转支付页面
                    that.$emit('change',{url:routeData.href,orderNo:res.data.order_no});
                }
            })
        },

        // 创建实时转账对象申请 返回订单
        transferMoneyToPay() {
            var that = this;
            var params = {};
            params.payAmt = that.utils.keepTwoDecimalFull(that.payParams.payAmt);
            params.payType = that.payParams.payType;
            params.memberId = that.payParams.memberId;
            params.collectionUuid = that.payParams.collectionUuid;
            that.newApi.corpTransferMoneyDetermine(params).then((res) => {
                if(res.isSuccess == 1){
                    const routeData = that.$router.resolve({path: '/toPay/' + res.data.order_no, query: {type: 2}});
                    //到newAdapay页面去跳转支付页面
                    that.$emit('change',{url:routeData.href,orderNo:res.data.order_no});
                }
            })
        },

        // 创建分账转账对象申请 返回订单
        delayedAccountToPay() {
            var that = this;
            var params = {};
            params.payAmt = that.utils.keepTwoDecimalFull(that.payParams.payAmt);
            params.payType = that.payParams.payType;
            params.collectionUuid = that.payParams.collectionUuid;
            params.orderNo = that.payParams.orderNo
            that.newApi.corpPenny(params).then((res) => {
                if(res.isSuccess == 1){
                    const routeData = that.$router.resolve({path: '/toPay/' + res.data.order_no, query: {type: 2}});
                    //到newAdapay页面去跳转支付页面
                    that.$emit('change',{url:routeData.href,orderNo:res.data.order_no});
                }
            })
        },
    }
}
</script>

<style scoped>

</style>