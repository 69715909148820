<template>
    <div style="width: 200px;height: 300px;">
    </div>
</template>

<script>

    export default {
        name: "PayLoading",
        components:{

        },
        data(){
            return{
            }
        },
        mounted() {
            this.openLoading("处理中，请等待");
        },
        methods:{
        }
    }
</script>

<style scoped>
</style>