<template>
    <div class="service-info" style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
        <div class="service-body">
            <div class="service-left">
                <div class="body-card">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>服务订单详情</div>
                    </div>
                    <div style="padding:20px 40px">
                        <div class="service-tab">
                            <div class="title">
                                服务名称
                            </div>
                            <div class="content" v-if="serviceData.talbeInfo">
                                {{serviceData.talbeInfo.name}}
                            </div>
                            <div class="title">
                                服务金额
                            </div>
                            <div class="content">
                                {{serviceData.totalAmount}}
                            </div>
                            <div class="title">
                                订单状态
                            </div>
                            <div class="content">
                                <span v-for="(item, index) in orderStatusList" :key="index">
                                    <span :style="{'color': item.color}" v-if="serviceData.status === item.status">
                                    {{item.name}}
                                </span>
                                </span>

                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                委托方
                            </div>
                            <div class="content" style="color: #57AAFF">
                                {{serviceData.nickName}}
                            </div>
                            <div class="title">
                                服务方
                            </div>
                            <div class="content" style="color: #57AAFF">
                                {{serviceData.sellerName}}
                            </div>
                            <div style="display: flex" v-if="serviceData.roomId">
                                <div class="title">
                                    来自room
                                </div>
                                <div class="content" style="color: #031F88">
                                    中规新土
                                </div>
                            </div>
                        </div>
                        <div class="service-tab" v-if="serviceData.talbeInfo">
                            <div class="title">
                                服务详情
                            </div>
                            <div class="demand-content" style="width: 90%" v-html="serviceData.talbeInfo.demand">
                            </div>
                        </div>

                        <div class="service-tab">
                            <table style="width: 100%;height: auto;overflow: hidden;font-size: 14px;" v-if="serviceData.talbeInfo">
                                <tr v-for="(item,index) in serviceData.talbeInfo.otherSpecifications" :key="index" style="border: 2px solid #EBEBEB;">
                                    <th class="bold" colspan="2" style="border: 2px solid #EBEBEB; width: 10%"><div>{{item.key}}</div></th>
                                    <div v-if="item.type !== 6">
                                        <td class="bold" v-if="item.type===1 || item.type===2" style="width: 336px; border-left: none">
                                            <div style="margin-left: 20px;">{{item.value}}</div>
                                        </td>
                                        <td class="bold" v-if="item.type === 4" style="width: 336px; border-left: none">
                                            <div v-for="(seleItem, ctIndex) in item.value" :key="ctIndex">
                                                <div v-if="item.data.includes(seleItem.radio)">
                                                    <div style="padding-bottom: 8px;">
                                                        <div style="margin: 8px 20px;">{{ seleItem.name }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="bold" v-if="item.type === 3 || item.type === 5" style="width: 336px; border-left: none">
                                            <div v-for="(selectItem, selectIndex) in item.value" :key="selectIndex">
                                                <div v-if="item.radio === selectItem.radio" style="margin: 8px 20px;">{{selectItem.name }}</div>
                                            </div>
                                        </td>
                                        <td class="bold" v-if="item.type === 1 || item.type === 2">
                                            <div v-if="item.status === 0">已选</div>
                                            <div v-if="item.status === 1">未选</div>
                                        </td>
                                        <td class="bold" v-if="item.type === 3 || item.type === 4 || item.type === 5">
                                            <div>备注</div>
                                        </td>
                                        <td class="bold" style="width: 480px">
                                            <div>
                                                {{item.content}}
                                            </div>
                                        </td>
                                    </div>

                                    <td class="bold" v-if="item.type === 6" style="width: 60%">
                                        <div style="width: calc(100% - 2px);border:1px solid #d0d0d0" >
                                            <div style="width: 100%;overflow:hidden;" v-for="(p,pindex) in item.value" :key="pindex" :style="pindex==item.value.length-1?'':'border-bottom: 1px solid #d0d0d0'">
                                                <div v-if="item.radio === p.radio">
                                                    <div style="width: 14%; overflow: hidden; float: left;text-align: center; margin-top: 10px">
                                                        <span style="height: auto;">{{ p.name }}</span>
                                                    </div>
                                                    <div style="width: calc(86% - 2px);overflow:hidden;border-left: 1px solid #d0d0d0">
                                                        <div style="overflow:hidden; float: left; width:35%; border-right: 1px solid #d0d0d0">
                                                            <div v-for="(k,kindex) in p.value" :key="kindex" style="line-height: 40px;height:auto;overflow:hidden;text-align: center;" :style="kindex==p.value.length-1?'':'border-bottom: 1px solid #d0d0d0'">{{ k }}</div>
                                                        </div>
                                                        <div style="width: calc(65% - 10px);overflow:hidden; padding-left: 8px; margin-top: 10px">
                                                            {{p.content}}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="service-tab">
                            <div class="title">
                                服务输入条件
                            </div>
                            <div style="width: 90%">
                                <div v-if="serviceData.serviceInput">
                                    <span v-for="(item, index) in serviceData.serviceInput.slice(0, serviceData.serviceInput.length - 1)" :key="index">{{index + 1}}.{{item}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                服务输入条件
                            </div>
                            <div style="width: 90%">
                                <div v-if="serviceData.serviceOutput">
                                    <span v-for="(item, index) in serviceData.serviceOutput.slice(0, serviceData.serviceOutput.length - 1)" :key="index">{{index + 1}}.{{item}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                服务达到的标准
                            </div>
                            <div style="width: 90%">
                                <div v-if="serviceData.serviceStandard">
                                    <span v-for="(item, index) in serviceData.serviceStandard.slice(0, serviceData.serviceStandard.length - 1)" :key="index">{{index + 1}}.{{item}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                签约时间
                            </div>
                            <div class="content">
                                {{serviceData.createTime}}
                            </div>
                            <div v-if="serviceData.expertList">
                                <div class="title" v-if="serviceData.expertList.length > 0">
                                    邀请专家
                                </div>
                            </div>
                            <div style="width: 44%" v-if="serviceData.expertList">
                                <div v-if="serviceData.expertList.length > 0">
                                <span style="margin-right: 10px" v-for="(item, index) in serviceData.expertList" :key="index">
                                    {{item.name}}
                                </span>
                                </div>
                            </div>
                            <div @click="toLookContract" class="hand op" v-if="serviceData.depositList.length === 2" style="color: #031F88; font-weight: 600;">
                                查看服务合同 >
                            </div>
                        </div>
                        <div v-if="serviceData.uuid===user.uuid || serviceData.sellerUuid===user.uuid" class="service-tab" style="border-bottom: none; margin-top: 10px">
                            <div style="width: 100%; display: flex; justify-content: end">
                                <div @click="cancelTheOrder" v-if="serviceData.status <= 3 && serviceData.depositList.length !== 1" class="foot-btn hand op" style="background-color: #D9D9D9; margin-right: 10px">撤销订单</div>
                                <div @click="applyForRefund" v-if="(serviceData.status === 4 || serviceData.status === 5) && serviceData.uuid===user.uuid" class="foot-btn hand op" style="background-color: #D9D9D9; margin-right: 10px">申请退款</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="body-card" v-if="serviceData.status > 3">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>服务成果</div>
                    </div>
                    <div style="padding-bottom: 30px" v-if="user.uuid !== serviceData.sellerUuid && serviceData.resultList.length <= 0">
                        <NullBox data="服务成果"></NullBox>
                    </div>
                    <div v-else>
                        <div style="padding:20px 40px">
                            <TabsResult :serviceData="serviceData"></TabsResult>
                        </div>
                        <div v-if="serviceData.status >= 4 && serviceData.expertList.length > 0">
                            <div>
                                <div class="work-title">
                                    <div class="work-icon"></div>
                                    <div>专家审查意见</div>
                                </div>
                                <div style="padding:20px 40px; overflow: hidden">
                                    <TabsExpert :serviceData="serviceData"></TabsExpert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="service-right">
                <div class="body-card" style="margin: 0; min-height: 200px">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>订单动态</div>
                    </div>
                    <ServerState :order="serviceData" v-if="user.uuid === serviceData.sellerUuid"></ServerState>
                    <ClientState :order="serviceData" v-if="user.uuid === serviceData.uuid"></ClientState>
                    <div v-for="(item, index) in serviceData.expertList" :key="index">
                        <ExpertState v-if="item.uuid === user.uuid" :order="serviceData"></ExpertState>
                    </div>

                </div>

                <div class="body-card" style="margin-top: 10px;" v-if="expertOrderList.length > 0 && serviceData.uuid === user.uuid">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>专家订单</div>
                    </div>
                    <div style="padding:16px 20px; overflow: auto; height: 400px;">
                        <div v-for="(item, index) in expertOrderList" :key="index">
                            <el-card style="margin-bottom: 10px">
                                <div style="display: flex; justify-content: space-between">
                                    <div style="display: flex;">
                                        <img :src="item.headIco" style="width: 48px; height: 48px; border-radius: 50%; margin-right: 10px">
                                        <div style="width: 130px">
                                            <div style="color: #2970FF; margin-bottom: 10px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.nickName}}</div>
                                            <div style="font-size: 12px; color: #999999">{{item.createTime}}</div>
                                        </div>
                                    </div>
                                    <div v-if="item.isPay === 0 && item.adapayStatus === -1">
                                        <div @click="noExpertOrder(item)" class="hand op no-bt" >取消订单</div>
                                        <div @click="payExpertOrder(item)" class="hand op bt">立即支付</div>
                                    </div>
                                    <div v-if="item.isPay === 0 && item.adapayStatus === 0">
                                        <div v-if="serviceData.paymentType.payType === 2" @click="toPayExpertOrder(item.orderNo)" class="hand op bt">继续支付</div>
                                        <div v-if="serviceData.paymentType.payType === 1" @click="payExpertOrder(item)" class="hand op bt">继续支付</div>
                                    </div>
                                    <div v-if="item.isPay === 1">
                                        <div style="margin-top: 16px; color: #7BC526">已付款</div>
                                    </div>
                                    <div v-if="item.isPay === 2">
                                        <div style="margin-top: 16px; color: #999999">已退款</div>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>

                <div class="body-card" style="margin-top: 10px;" v-if="serviceData.uuid === user.uuid && serviceData.status > 3">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>邀请专家</div>
                    </div>
                    <div style="padding:16px 20px; overflow: auto; height: 400px;">
                        <div v-for="(item, index) in serviceData.expertInvite" :key="index">
                            <el-card style="margin-bottom: 10px">
                                <div style="display: flex; justify-content: space-between">
                                    <div style="display: flex;">
                                        <img :src="item.headIco" style="width: 48px; height: 48px; border-radius: 50%; margin-right: 10px">
                                        <div style="width: 140px">
                                            <div style="color: #2970FF; margin-bottom: 4px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.nickName}}</div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div v-if="item.isSendOrders === -1" @click="inviteExperts(item)" class="hand op foot-btn" style="background-color: #031F88; color: #FFFFFF; width: 40px; padding: 2px 6px; margin-top: 10px">邀请</div>
                                    <div v-if="item.isPay === 1 && item.isSendOrders === 0" style="color: #BDBFC1; margin-top: 10px">待接单</div>
                                    <div v-if="item.isPay === 0 && item.isSendOrders === 0" style="color: #BDBFC1; margin-top: 10px">待付款</div>
                                    <div v-if="item.isPay === 1 && item.isSendOrders === 1" style="color: #BDBFC1; margin-top: 10px">已接单</div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>

                <div class="body-card" style="margin-top: 10px; min-height: 200px">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>订单日志</div>
                    </div>
                    <div style="padding:16px 20px">
                        <div v-for="(item, index) in serviceLogList" :key="index" style="margin-bottom: 20px">
                            <div style="color: #999999; font-size: 14px; margin-bottom: 6px">{{item.createTime}}</div>
                            <el-card>
                                <div style="display: flex">
                                    <div>
                                        <div>{{item.content}}</div>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--保证金弹窗-->
        <el-dialog :visible.sync="showGuarantee" width="40%" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="box-head">
                请支付您的服务保证金
                <img src="../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="function() {showGuarantee = false; showGuaranteePay = false}">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <div style="margin-bottom: 20px;display: flex; justify-content: space-between; height: 50px; border-top: 1px solid #DEDDDD; border-bottom: 1px solid #DEDDDD;">
                    <div>
                        <div v-if="serviceData.paymentType.payType == 2" style="color: #999999;">手续费说明：机构账户{{procedureFee}}元/笔</div>
                        <div style="color: #999999; margin-top: 10px">保证金说明：保证金取服务总金额的10%，保证金=服务总金额x10%</div>
                    </div>
                    <div style="font-size: 18px; font-weight: 600; line-height: 50px">
                        <span>支付金额：</span>
                        <span style="color: #EE3F4D">￥{{guarantee + procedureFee}}</span>
                    </div>
                </div>

                <!-- 快捷卡支付  -->
                <div v-if="serviceData.paymentType.payType == 1">
                    <div v-if="guarantee > 0" style="width: 400px;margin-left: 28%;margin-top: 10px;">
                        <!--        支付类型参考-->
                        <aa-da-pay-code v-if="guaranteeOrderNo" type="3" :payAmt="guarantee" :payType="12" OrderType="12" :orderNo="guaranteeOrderNo" @change="changeOrder($event)" ref="child"></aa-da-pay-code>
                    </div>
                </div>
                <div v-if="serviceData.paymentType.payType == 2">
                    <div v-if="guarantee > 0" style="width: 400px;margin-left: 28%;margin-top: 10px;">
                        <!--        支付类型参考-->
                        <OrganPayCode @closeSubPay="closeSubPay" v-if="guaranteeOrderNo && showGuaranteePay" type="3" :payAmt="guarantee + procedureFee" :payType="12" :orderNo="guaranteeOrderNo" ref="child"></OrganPayCode>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--服务金弹窗-->
        <el-dialog :visible.sync="showServiceFee" width="40%" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="box-head">
                请支付您的服务费用
                <img src="../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="function(){showServiceFee = false;showAmountPay = false}">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <div style="margin-bottom: 20px">
                    <span class="gur-title">服务名称：</span>
                    <span style="font-size: 18px">{{serviceData.servicenName}}</span>
                </div>
                <div style="margin-bottom: 20px">
                    <span class="gur-title">服务需求：</span>
                    <!--                    <div v-html=""></div>-->
                </div>
                <div style="margin-bottom: 20px">
                    <span class="gur-title">服务总金额：</span>
                    <span class="gur-title" style="color: #EE3F4D">￥{{serviceData.totalAmount}}</span>
                </div>
                <div style="margin-bottom: 20px;display: flex; justify-content: space-between; height: 50px; border-top: 1px solid #DEDDDD; border-bottom: 1px solid #DEDDDD;">
                    <div>
                        <div v-if="serviceData.paymentType.payType == 2" style="color: #999999">手续费说明：机构账户{{procedureFee}}元/笔</div>
                        <div style="color: #999999; margin-top: 10px">服务金额说明：支付总金额，分批支付给服务方</div>
                    </div>
                    <div style="font-size: 18px; font-weight: 600">
                        <span>支付金额：</span>
                        <span style="color: #EE3F4D">￥{{serviceData.totalAmount + procedureFee}}</span>
                    </div>
                </div>
                <!-- 快捷卡支付  -->
                <div v-if="serviceData.paymentType.payType == 1">
                    <div v-if="serviceData.totalAmount > 0" style="width: 400px;margin-left: 28%;margin-top: 10px;">
                        <!--        支付类型参考-->
                        <aa-da-pay-code type="3" :payAmt="serviceData.totalAmount" :payType="11" OrderType="12" :orderNo="servicePayOrderNo" :collectionUuid="serviceData.sellerUuid" @change="changeOrder($event)" ref="child"></aa-da-pay-code>
                    </div>
                </div>
                <div v-if="serviceData.paymentType.payType == 2">
                    <div v-if="serviceData.totalAmount > 0" style="width: 400px;margin-left: 28%;margin-top: 10px;">
                        <!--        支付类型参考-->
                        <OrganPayCode @closeSubPay="closeSubPay" v-if="showAmountPay" :payType="11" :payAmt="serviceData.totalAmount + procedureFee" type="3" :orderNo="$route.params.id" :collectionUuid="serviceData.sellerUuid" ref="child"></OrganPayCode>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--邀请专家弹窗-->
        <el-dialog :visible.sync="showExpert" width="46%" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div v-if="showOrder === false">
                <div class="top">
                    邀请专家审查订单
                    <img src="../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showExpert = false">
                </div>
                <div style="padding: 20px 40px; overflow: hidden">
                    <div style="display: flex; justify-content: space-between; margin-bottom: 40px">
                        <div style="width: 70%; margin-right: 30px">
                            <div class="title-form">咨询标题</div>
                            <el-input v-model="reviewData.reviewTitle"></el-input>
                        </div>
                        <div style="width: 30%">
                            <div class="title-form">咨询费用</div>
                            <el-input v-model="reviewData.payAmt"></el-input>
                        </div>
                    </div>

                    <div>
                        <div class="title-form">咨询内容</div>
                        <div>
                            <el-input
                                    type="textarea"
                                    :rows="8"
                                    placeholder="请输入咨询内容"
                                    v-model="reviewData.reviewContent">
                            </el-input>
                        </div>
                    </div>

                    <div style="margin: 20px 0;">
                        <div @click="creatOrder" class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF; margin: 0 auto">生成订单</div>
                    </div>
                </div>
            </div>
            <div v-if="showOrder === true">
                <div class="box-head">
                    请确认您的订单详情：
                    <img src="../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="function() {showExpertPay = false;showExpert = false}">
                </div>

                <div style="padding: 30px 40px; display: flex">
                    <div style="text-align: center; width: 90px; margin-right: 20px">
                        <img style="width: 90px; height: 90px" :src="expertInfo.headIco">
                        <div style="font-size: 16px; font-weight: 600; margin-top: 10px">{{expertInfo.nickName}}</div>
                    </div>
                    <div style="font-size: 16px;">
                        <div style="font-weight: 600; margin-bottom: 6px">
                            <span>咨询标题：</span>
                            <span style="color: #031F88">{{reviewData.reviewTitle}}</span>
                        </div>
                        <div style="font-weight: 600; margin-bottom: 6px">
                            <span>咨询内容：</span>
                            <span style="color: #999999; font-weight: 400">{{reviewData.reviewContent}}</span>
                        </div>
                        <div style="font-weight: 600; margin-bottom: 6px">
                            <span>咨询费用：</span>
                            <span style="color: #EE3F4D">￥{{reviewData.payAmt}}</span>
                        </div>
                        <div v-if="serviceData.paymentType.payType == 2" style="width: 600px;margin-bottom: 20px;display: flex; justify-content: space-between; height: 50px; line-height: 50px; border-top: 1px solid #DEDDDD; border-bottom: 1px solid #DEDDDD;">
                            <div style="color: #999999">手续费说明：机构账户{{procedureFee}}元/笔</div>
                            <div style="font-size: 18px; font-weight: 600">
                                <span>支付金额：</span>
                                <span style="color: #EE3F4D">￥{{Number(reviewData.payAmt) + procedureFee}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <!-- 快捷卡支付  -->

                <div style="overflow: hidden; width: 100%" v-if="reviewData.payAmt > 0">
                    <div v-if="serviceData.paymentType.payType == 1">
                        <div style="width: 300px;margin: 10px auto; overflow: hidden">
                            <!--        支付类型参考-->
                            <aa-da-pay-code type="3" :payAmt="reviewData.payAmt" :payType="9" :orderNo="expertOrderNo" @change="changeOrder($event)" :collectionUuid="expertInfo.uuid" ref="child"></aa-da-pay-code>
                        </div>
                    </div>

                    <div v-if="serviceData.paymentType.payType == 2">
                        <div v-if="serviceData.totalAmount > 0" style="width: 400px;margin-left: 28%;margin-top: 10px;">
                            <!--        支付类型参考-->
                            <OrganPayCode @closeSubPay="closeSubPay" v-if="showExpertPay" :payAmt="Number(reviewData.payAmt) + procedureFee" type="3" :payType="9" :orderNo="expertOrderNo" :collectionUuid="expertInfo.uuid" ref="child"></OrganPayCode>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div style="margin: 20px 0; padding-bottom: 20px">
                        <div @click="passOrder" class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF; margin: 0 auto">确认订单</div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--机构账号选择支付类型-->
        <el-dialog :close-on-click-modal="false" :visible.sync="showPayType" width="40%" :show-close="false" class="event-form">
            <div class="box-head" style="text-align: center; padding-top: 30px">
                请选择支付的账户类型
                <img src="../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showPayType = false">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <div style="display: flex; justify-content: center; margin-bottom: 10px">
                    <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;; margin-right: 30px" @click="choosePayType(2)">机构账户</div>
                    <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="choosePayType(1)">个人账户</div>
                </div>
            </div>
        </el-dialog>

        <pay-loading v-if="loading" ></pay-loading>

        <!--是否支付弹窗-->
        <is-pay-show :showMessage="showMessage"></is-pay-show>
    </div>
</template>

<script>
    import ServerState from "../components/service/ServerState";
    import ClientState from "../components/service/ClientState";
    import ExpertState from "../components/service/ExpertState";
    import AaDaPayCode from "../../../components/common/Pay/AaDaPayCode";
    import PayLoading from "../../../components/common/Pay/PayLoading";
    import TabsResult from "../components/service/TabsResult";
    import TabsExpert from "../components/service/TabsExpert";
    import NullBox from "../components/service/NullBox";
    import OrganPayCode from "@/components/common/Pay/organ/organPayCode";
    import IsPayShow from "../../../components/common/Pay/IsPayShow";
    export default {
        name: "serviceInfo",
        components: {
            OrganPayCode,
            NullBox,
            TabsExpert,
            TabsResult, PayLoading, AaDaPayCode, ExpertState, ClientState, ServerState,IsPayShow},
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        data() {
            return {
                orderNo: '', // 统一更换的订单号

                serviceData: {
                    expertList: []
                },

                num: 1,
                show: 1,
                loading: false,

                // 机构账户手续费
                procedureFee: 0,

                // 支付账户类型
                showPayType: false,


                // room信息
                roomInfo: {},

                payType: '',

                // 保证金
                showGuarantee: false,
                guarantee: '',
                orderType: '',
                guaranteeOrderNo: '',
                showGuaranteePay: false,

                // 服务金
                showServiceFee: false,
                showAmountPay: false,
                servicePayOrderNo: '',

                // 邀请专家
                showExpert: false,
                showOrder: false,
                showExpertPay: false,
                expertOrderList: [],
                expertOrderNo: '',
                expertInfo: {},
                reviewData: {
                    reviewTitle: '',
                    reviewContent: '',
                    payAmt: 0
                },

                // 专家意见
                content: '',

                // 订单状态
                orderStatusList: [
                    {name: '待缴保证金', color: '#F44336', status: 2},
                    {name: '待付款', color: '#F44336', status: 3},
                    {name: '进行中', color: '#FF9800', status: 4},
                    {name: '待验收', color: '#FF9800', status: 5},
                    {name: '已完成', color: '#8BC34A', status: 9},
                    {name: '已撤单', color: '#999999', status: 10},
                    {name: '已退款', color: '#999999', status: 11},
                    {name: '退款中', color: '#999999', status: 7},
                ],

                order: {},
                activities: [
                    {
                        content: '委托方已下订单，请查看订单',
                        type: 1
                    },
                    {
                        content: '双方签订合同，订单已生效',
                        type: 2
                    },
                    {
                        content: '服务方开始工作',
                        type: 3
                    },
                    {
                        content: '服务方已提交初稿方案',
                        type: 4
                    },
                    {
                        content: '服务方已上传修改方案',
                        type: 5
                    }
                ],

                // 订单日志
                serviceLogList: [],
                showMessage:false
            }
        },


        mounted() {
            var that = this;
            this.getServiceInfo();
            this.getExpertOrderList();
            this.getServiceLog();
            //监听支付回调
            this.$EventBus.$on("payMsg", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.loading = false;
                        that.closeSubPay();
                        setTimeout(function () {
                            that.getServiceInfo();
                            that.getExpertOrderList();
                            that.getServiceLog();
                        },1000);

                    }
                });
            });
            //监听支付
            this.$EventBus.$on("toPay", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100 && data.payType == 12) {
                        if(this.serviceData.paymentType.payType == 1){
                            this.toPaySelect();
                        }else{
                            this.goOrganPay('guarantee')
                        }
                    }else if (data.code == 100 && data.payType == 11){
                        if(this.serviceData.paymentType.payType == 1){
                            this.toPaySelect();
                        }else{
                            this. goOrganPay('amount');
                        }
                    }else if (data.code == 100 && data.payType == 9){
                        if(this.serviceData.paymentType.payType == 1){
                            this.toPaySelect();
                        }else{
                            this.goOrganPay('expert')
                        }
                    }
                });
            });
        },

        methods: {
            // 关闭支付
            closeSubPay() {
                this.showGuarantee = false;
                this.showGuaranteePay = false;
                this.showServiceFee = false;
                this.showshowAmountPay = false;
                this.showExpert = false;
                this.showExpertPay = false
            },

            // 获取服务订单日志
            getServiceLog() {
                this.newApi.shopServiceOrderLog({serviceOrder: this.$route.params.id}).then(res => {
                    if (res.isSuccess === 1) {
                        this.serviceLogList = res.data;
                    }
                })
            },

            // 查看合同
            toLookContract() {
                this.newApi.getContractUrl({orderNo: this.$route.params.id}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.b(res.data);
                    } else {
                        this.utils.err('合同暂未生成，请稍后重试')
                    }
                })
            },

            // 撤销订单
            cancelTheOrder() {
                if (this.serviceData.status <= 2) {
                    this.utils.confirm('确定撤销订单吗？', () => {
                        this.loading = true;
                        this.newApi.cancelServiceOrder({serviceOrderNo: this.$route.params.id}).then(res => {
                            if (res.isSuccess === 1) {
                                this.loading = false;
                                this.utils.sus(res.data);
                                this.getServiceInfo();
                            }
                        })
                    })
                } else {
                    this.utils.confirm('撤销订单可能扣除您的保证金，确定撤销订单吗？', () => {
                        this.loading = true;
                        this.newApi.cancelServiceOrder({serviceOrderNo: this.$route.params.id}).then(res => {
                            if (res.isSuccess === 1) {
                                this.loading = false;
                                this.utils.sus(res.data);
                                this.getServiceInfo();
                            }
                        })
                    })
                }

            },

            // 申请退款
            applyForRefund() {
                this.utils.confirm('申请退款将全额扣除您的保证金，确定申请退款吗？', () => {
                    this.loading = true;
                    this.newApi.serviceOrderLaterCancel({serviceOrderNo: this.$route.params.id}).then(res => {
                        if (res.isSuccess === 1) {
                            this.loading = true;
                            this.utils.sus(res.data);
                            this.getServiceInfo();
                        }
                    })
                })
            },

            // 获取专家订单
            getExpertOrderList() {
                this.newApi.examineOrderList({
                    serviceOrderNo: this.$route.params.id
                }).then(res => {
                    this.expertOrderList = res.data;
                })
            },

            // 生成专家订单
            creatOrder() {
                if (!this.reviewData.reviewTitle || !this.reviewData.reviewContent) {
                    this.utils.err('请填写完整订单信息')
                    return
                }
                if (!/^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/.test(this.reviewData.payAmt)) {
                    this.utils.err('请输入正确的金额')
                    return
                }
                this.newApi.addExamineOrder({
                    serviceOrderNo: this.$route.params.id,
                    expertUuid: this.expertInfo.uuid,
                    serviceTitle: this.reviewData.reviewTitle,
                    serviceContent: this.reviewData.reviewContent,
                    payAmt: this.reviewData.payAmt
                }).then(res => {
                    if (res.isSuccess === 1) {
                        this.expertOrderNo = res.data;
                        this.getServiceInfo();
                        this.getExpertOrderList();
                        this.utils.sus('生成订单成功');
                        this.showOrder = true;
                    }
                })
            },

            // 免费专家订单确认
            passOrder() {
                this.reviewData = {
                    reviewTitle: '',
                    reviewContent: '',
                    payAmt: ''
                }
                this.showOrder = false;
                this.showExpert = false;
            },

            // 邀请专家
            inviteExperts(item) {
                //判断下专家是否绑卡
                if(item.isBindCar == -1){
                    this.utils.err("专家未绑卡，不能邀请");
                    return false;
                }
                if(this.serviceData.status > 5){
                    this.utils.err("已结单无法邀请");
                    return false;
                }
                this.passOrder()
                this.expertInfo = item;
                this.showExpert = true;
            },

            // 继续支付专家订单
            toPayExpertOrder(orderNo) {
                if(this.serviceData.status > 5){
                    this.utils.err("已结单无法邀请");
                    return false;
                }
                const routeData = this.$router.resolve({path: '/user/money/toPay/' + orderNo, query: {type: 2}});
                this.utils.b(routeData.href);
            },

            // 支付专家订单
            payExpertOrder(item) {
                this.newApi.payExamineOrder({orderNo: item.orderNo}).then(res =>{
                    if (res.isSuccess === 1) {
                        this.reviewData.reviewTitle = res.data.serviceTitle;
                        this.reviewData.reviewContent = res.data.serviceContent;
                        this.reviewData.payAmt = res.data.payAmt;
                        this.expertInfo.uuid = res.data.expertUuid;
                        this.expertInfo.nickName = item.nickName;
                        this.expertInfo.headIco = item.headIco;
                        this.expertInfo.major = item.major;
                        this.expertOrderNo = res.data.orderNo;
                        this.showExpert = true;
                        this.showOrder = true;
                    }
                })
            },

            // 取消专家订单
            noExpertOrder(item) {
                this.loading = true;
                this.newApi.cancelExamineOrder({orderNo: item.orderNo}).then(res =>{
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.loading = false;
                        this.getServiceInfo();
                        this.getExpertOrderList();
                    }
                })
            },

            // 获取room详情
            getRoom() {
                this.newApi.getRoomInfo({roomId: this.serviceData.roomId}).then(res => {
                    this.roomInfo = res.data
                })
            },

            // 机构支付
            goOrganPay(type) {
                if (type === 'guarantee') {
                    this.showGuaranteePay = true
                }
                if (type === 'amount') {
                    this.showAmountPay = true
                }
                if (type === 'expert') {
                    this.showExpertPay = true
                }
                this.showMessage = true;
                this.showGuarantee = false;
                this.showServiceFee = false;
                this.showExpert = false;
            },

            // 保证金支付
            selectGuarantee() {
                this.showPayType = true;
            },

            // 选择支付类型
            choosePayType(type) {
                this.newApi.selectPaymentType({serviceOrderNo: this.$route.params.id, paymentType: type}).then(res => {
                    if (res.isSuccess === 1) {
                        this.showPayType = false;
                        this.getServiceInfo();
                        this.getGuarantee();
                    }
                })
            },

            // 获取保证金并展示弹窗
            getGuarantee() {
                var param = {};
                var that = this;
                param = {
                    orderNo: this.$route.params.id,
                    uuid: this.serviceData.uuid,
                    sellerUuid: this.serviceData.sellerUuid,
                }
                this.newApi.getDepositAmount(param).then(res =>{
                    if (res.isSuccess === 1) {
                        this.guarantee = res.data;
                        var type = 0;
                        if (this.user.uuid == this.serviceData.uuid) {
                            type = 1;
                        }
                        if (this.user.uuid == this.serviceData.sellerUuid) {
                            type = 2;
                        }
                        this.newApi.addDepositOrder({type: type,payAmt: this.guarantee, serviceOrder: this.$route.params.id}).then((res) => {
                            if (res.isSuccess == 1) {
                                that.showGuarantee = true;
                                that.guaranteeOrderNo = res.data.orderNo;
                            }
                        })
                    }
                })
            },

            // 服务金额信息及弹窗
            getServiceFee() {
                this.showServiceFee = true;
            },

            // 支付保证金
            toPayGuarantee(params) {
                var that = this;
                that.loading = true;
                that.newApi.settleAccountPennyDetermine(params).then((res) => {
                    that.loading = false;
                    if(res.isSuccess == 1){
                        that.getServiceInfo();
                    }
                })
            },

            //子组件传过来的订单号 此方法在父页面必写
            changeOrder(order){
                this.orderNo = order;
                this.guaranteeOrderNo = order;
                this.expertOrderNo = order;
                this.servicePayOrderNo = order;
            },

            //父组件获取子组件的验证码 this.$refs.child.childMethods() 必写
            toPaySelect(){
                var that = this;
                var obj = this.$refs.child.childMethods();
                if (obj.code == "") {
                    that.utils.err("请填写验证码");
                    return false;
                }
                if (obj.payPwd == "") {
                    that.utils.err("请填写密码");
                    return false;
                }
                let params = {};
                params.orderNo = that.orderNo;
                params.code = obj.code;
                params.payPwd = obj.payPwd;
                // let parameter = {};

                // parameter.payAmt = that.guarantee;
                // parameter.serviceOrder = that.$route.params.id; //服务订单号
                that.toPayGuarantee(params);
            },

            // 获取服务订单信息
            getServiceInfo() {
                this.newApi.getServiceOrderInfo({orderNo: this.$route.params.id}).then(res =>{
                    if (res.isSuccess === 1) {
                        this.serviceData = res.data;
                        if (this.serviceData.paymentType.payType == 2) {
                            this.procedureFee = 10;
                        } else {
                            this.procedureFee = 0
                        }
                        if (this.serviceData.roomId) {
                            this.getRoom();
                        }
                        this.servicePayOrderNo = res.data.payOrderNo;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .service-body {
        width: 1500px;
        display: flex;
        margin: 100px auto;
    }

    .service-left {
        width: 1136px;
        margin-right: 10px;
    }

    .service-right {
        width: 354px;
        margin-top: 10px;
        overflow: hidden;
    }

    .head-card {
        width: 100%;
        height: 200px;
        background-color: #FFFFFF;
    }

    .body-card {
        margin-top: 10px;
        width: 100%;
        background-color: #FFFFFF;
    }

    .service-body .work-title {
        display: flex;
        line-height: 20px;
        padding-top: 16px;
        font-size: 18px;
        font-weight: 600;
        color: #031F88;
    }

    .service-body .work-title .work-icon {
        width: 6px;
        height: 20px;
        background-color: #031F88;
        margin-right: 14px;
    }

    .service-tab {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px #E1E1E1 solid;
    }

    .service-body .title {
        width: 80px;
        margin-right: 10px;
        color: #888888;
    }

    .service-body .content {
        width: 260px;
    }

    .service-info .foot-btn {
        width: 108px;
        height: 24px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 6px;
    }

    .service-info .gur-title {
        font-size: 18px;
        font-weight: 600;
    }

    .service-info .box-head {
        padding: 10px;
        font-size: 18px;
        font-weight: 600;
    }

    .event-form .top {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    .service-info .bt {
        padding: 4px 6px;
        background-color: #FF0000;
        font-size: 12px;
        color: #ffffff;
        border-radius: 5px;
    }

    .service-info .no-bt {
        padding: 4px 6px;
        background-color: #C6C6C6;
        font-size: 12px;
        color: #ffffff;
        border-radius: 5px;
        margin-bottom: 8px;
    }

    .event-form .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .demand-content>>>img {
        max-width: 100%;
    }

    .demand-content>>>table {
        max-width: 100%;
    }

    .tabs-box>>>img {
        max-width: 100%;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }
    .bold {
        height: 30px;
    }
    table {
        border-collapse: collapse;
        height: auto;
        overflow: hidden;
    }

    th, td {
        border-left: 1px solid #d0d0d0;
        padding: 8px;
    }

    .th[data-v-16c18cb4], td[data-v-16c18cb4]{
        padding: 0;
    }

</style>
